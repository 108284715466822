<template>
  <div ref="map" class="w-full h-full">
    <annotation v-if="isAnno" :annotation="currentAnnotation" @close="isAnno = false" />
  </div>
</template>

<script>
import L from 'leaflet'
import BASELAYERS from '@/components/map/baseLayers.js'
import OVERLAYS from '@/components/map/overlays.js'
import '@/utils/movingMarker.js'
import trackPlayer from '@/mixins/trackPlayer.js'
import mapForReports from '@/mixins/mapForReports.js'

import geozonesMixin from '@/mixins/geozones.js'
import measureMixin from '@/mixins/measure.js'
import tripsAndRoutesMixin from '@/mixins/tripsAndRoutes.js'
import {
  mapForNotifications,
  mapForMonitoring,
  mapForReportsTable,
  mapForEvents
} from './mixins'
import websocketHandler from '@/mixins/websocketHandler.js'
import { mapGetters, mapMutations, mapState } from 'vuex'

import createMarker from '@/mixins/createMarker.js'

import { moveEndMapForBoxTrack } from '@/services/reports/boxTrackReport'

export default {
  name: 'LeafletMap',
  components: {
    annotation: () => import('./tools/annotation.vue')
  },
  mixins: [
    trackPlayer,
    mapForReports,
    mapForMonitoring,
    geozonesMixin,
    measureMixin,
    tripsAndRoutesMixin,
    mapForNotifications,
    websocketHandler,
    mapForReportsTable,
    mapForEvents,
    createMarker
  ],
  props: {
    isActiveMonitoringButton: {
      type: Boolean,
      default: false
    },
    isMonitoringManipulation: {
      type: Boolean,
      default: false
    },
    markerCluster: {
      type: Object,
      default: () => {}
    },
    isMonitoring: {
      type: Boolean,
      default: false
    },
    spinnerLoading: {
      type: Boolean
    }
  },

  data() {
    return {
      wsStarted: false,
      isAnno: false,
      searchAddressMarker: null,
      map: null,
      labelEngine: null,
      baseLayers: BASELAYERS,
      tempOverlay: OVERLAYS.Temperature,
      cloudsOverlay: OVERLAYS.Clouds,
      windOverlay: OVERLAYS.Wind,
      baseLayer: {},
      selectedLayerName: 'Yandex Map',
      state: {},
      layers: [],
      automaticSetView: false,
      featureGroupStops: L.layerGroup(),
      currentAnnotation: null,
      coordinateForTrack: null,
      markerGroup: L.featureGroup(),
      markerGroupUnits: L.markerClusterGroup({
        chunkedLoading: true,
        maxClusterRadius: 100,
        removeOutsideVisibleBounds: true,
        iconCreateFunction(cluster) {
          return L.divIcon({
            className: 'marker-cluster-group__object',
            html: `<div class="marker-group marker-group__object"><span>${cluster.getChildCount()}</span></div>`
          })
        }
      }),
      markersCluster: L.markerClusterGroup({
        chunkedLoading: true,
        maxClusterRadius: 100,
        removeOutsideVisibleBounds: true,
        iconCreateFunction(cluster) {
          return L.divIcon({
            className: 'marker-cluster-group__object',
            html: `<div class="marker-group marker-group__object"><span>${cluster.getChildCount()}</span></div>`
          })
        }
      }),
      toggleNotification: null,
      currentZoom: null,
    }
  },

  computed: {
    center: {
      set(center) {
        this.automaticSetView && this.$store.dispatch('map/setCenter', center)
      },
      get() {
        return this.$store.state.map.center
      }
    },

    zoom: {
      set(zoom) {
        // console.log(zoom, 'computed zoom');
        // this.automaticSetView && this.$store.dispatch('map/setZoom', zoom)
        this.currentZoom = zoom
        this.$store.dispatch('map/setZoom', zoom)
      },
      get() {
        return this.$store.state.map.zoom
      }
    },
    ...mapGetters('login', [
      'activeCompanyId',
      'changingCompany',
      'userId',
      'centerMapClear'
    ]),
    ...mapGetters({
      vuexSearchMonitoringListOnMap: 'monitoringNew/vuexSearchMonitoringListOnMap',
      vuexMarkersUnitOnMap: 'monitoringNew/vuexMarkersUnitOnMap',
      vuexIsActiveAllEye: 'monitoringNew/vuexIsActiveAllEye',
      track: 'monitoring/getTrack',
      getTrackBoxPointIncrease: 'monitoringNew/getTrackBoxPointIncrease',
      getTrackBoxPoint: 'monitoringNew/getTrackBoxPoint',

    }),
    ...mapState('reports', {
      stopsToShow: state => state.stopsToShow
    }),
    ...mapState('monitoring', {
      markersObjectGroupMarker: state => state.markersObjectGroupMarker,
      isCloseMontoringAnno: state => state.isCloseMontoringAnno
    }),

    ...mapState('map', {
      companyChange: state => state.companyChange,
      showMarkerFromCluster: state => state.showMarkerFromCluster,
      radiusValue: state => state.radiusValue,
      markerClusterNotifyAnxiousMarker: state => state.markerClusterNotifyAnxiousMarker,
      markerClusterNotifyMarker: state => state.markerClusterNotifyMarker,
      isCloseMapAnnot: state => state.isCloseMapAnnot,
      isStopShowing: state => state.isStopShowing,
      isHidingNotification: state => state.isHidingNotification,
      isShouldGroupObjects: state => state.isShouldGroupObjects
    })
  },

  watch: {
    companyChange() {
      // if there is no cluster in monitoring. then we should clear the markers from the map when change company
      this.markerGroupUnits.clearLayers()
    },
    isShouldGroupObjects(val) {
      if (this.vuexIsActiveAllEye) {
        if (!val) {
          this.markerCluster.clearLayers()
          this.$store.commit('map/SET_RADIUS_VALUE', 0)
        } else {
          this.markerGroupUnits.clearLayers()
          this.$store.commit('map/SET_RADIUS_VALUE', 80)
        }
      }
    },
    isMonitoringButton(val) {
      this.isMonitoringButtonArrow = val
    },

    isCloseMapAnnot() {
      this.isAnno = false
    },

    centerMapClear() {
      if (this.searchAddressMarker) {
        this.map.removeLayer(this.searchAddressMarker)
      }
    },

    radiusValue(val) {
      this.markerGroupUnits.options.maxClusterRadius = val

      if (this.vuexIsActiveAllEye) {
        this.markerGroupUnits.clearLayers()

        this.vuexSearchMonitoringListOnMap.forEach(item => {
          if (!item.latlon) return
          this.addVuexMarkersOnMap({ id: item.id, unit: item })
          this.addVuexMarkersUnitOnMap({
            id: item.id,
            unit: this.helperGetMarker(item, item.id)
          })

          this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])

          // this.markerCluster.addLayer(this.vuexMarkersUnitOnMap[item.id])
          // this.deleteVuexMarkerUnitOnMap(item)
        })
      }

      if (this.markersObjectGroup) {
        this.map.removeLayer(this.markersObjectGroup)
        this.markersObjectGroup = L.markerClusterGroup({
          chunkedLoading: true,
          maxClusterRadius: val,
          iconCreateFunction(cluster) {
            return L.divIcon({
              className: 'marker-cluster-group__object',
              html: `<div class="marker-group marker-group__object"><span>${cluster.getChildCount()}</span></div>`
            })
          }
        })
        this.map.addLayer(this.markersObjectGroup)
        this.markersObjectGroupMarker.forEach(marker => {
          this.markersObjectGroup.addLayer(marker)
        })
      }

      if (this.markerClusterNotifyAnxious) {
        this.map.removeLayer(this.markerClusterNotifyAnxious)

        this.markerClusterNotifyAnxious = L.markerClusterGroup({
          chunkedLoading: true,
          maxClusterRadius: val,
          iconCreateFunction(cluster) {
            return L.divIcon({
              className: 'marker-cluster-group__notify-anxious',
              html: `<div class="marker-group marker-group__notify-anxious"><span>${cluster.getChildCount()}</span></div>`
            })
          }
        })
        this.map.addLayer(this.markerClusterNotifyAnxious)
        this.markerClusterNotifyAnxiousMarker.forEach(marker => {
          this.markerClusterNotifyAnxious.addLayer(marker)
        })
      }

      if (this.markerClusterNotify) {
        this.map.removeLayer(this.markerClusterNotify)

        this.markerClusterNotify = L.markerClusterGroup({
          chunkedLoading: true,
          maxClusterRadius: val,
          iconCreateFunction(cluster) {
            return L.divIcon({
              className: 'marker-cluster-group__notify',
              html: `<div class="marker-group marker-group__notify"><span>${cluster.getChildCount()}</span></div>`
            })
          }
        })
        this.map.addLayer(this.markerClusterNotify)
        this.markerClusterNotifyMarker.forEach(marker => {
          this.markerClusterNotify.addLayer(marker)
        })
      }
    },

    showMarkerFromCluster(val) {
      val.addTo(this.map)
      this.map.setView(val.getLatLng(), 15)
    },

    spinnerLoading(val) {
      if(!val) {
        this.$emit('set-websocket');
        this.wsStarted = true;
      }
    },

    layer() {
      this.selectedLayerName = this.layer
      this.changeBaseLayer()
    },
    selectedLayerName: {
      handler() {
        // если были обьекты на карте снова добавляем их в кластеры
        const keysFromObj = new Set(Object.keys(this.vuexMarkersUnitOnMap))
        const foundObjects = this.vuexSearchMonitoringListOnMap.filter(item =>
          keysFromObj.has(item.id)
        )
        foundObjects.forEach(el => {
          this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[el.id])
        })
      }
    },

    changingCompany() {
      this.markerCluster.clearLayers()

      Object.values(this.drawenGeozones).forEach(geozone => {
        geozone.removeFrom(this.map)
      })

      this.drawenGeozones = {}
      if (this.monitoringFeatureGroup) {
        this.monitoringFeatureGroup.clearLayers()
      }
      if (this.markerClusterNotifyAnxious) {
        this.markerClusterNotifyAnxious.clearLayers()
      }
      // if (this.markerClusterNotify) {
      //   this.markerClusterNotify.clearLayers()
      // }
      if (this.markersObjectGroup) {
        this.markersObjectGroup.clearLayers()
      }
      if (this.monitoringMarkers) {
        Object.values(this.monitoringMarkers).forEach(marker =>
          marker.removeFrom(this.map)
        )
        this.monitoringMarkers = {}
      }
      this.resetMonitoring()
      // if (this.notificationMarkers) {
      //   Object.values(this.notificationMarkers).forEach((notifyMarker) =>
      //     notifyMarker.removeFrom(this.map)
      //   )
      //   this.notificationMarkers = {}
      // }
      // this.$emit('reset-notification')

      this.geozones.forEach(geozone => {
        if (geozone.geometry && geozone.geometry.length !== 0) {
          switch (geozone.type.key) {
            case 'polygon':
              const polygon = L.polygon(geozone.geometry, {
                color: geozone.color.key,
                opacity: 0.3,
                type: geozone.icon.key,
                renderer: this.myRenderer
              }).bindTooltip(geozone.name, {
                pane: 'geozonenamePane',
                className: this.labelClassNames[geozone.color.value],
                direction: 'bottom'
              })

              polygon.options.shape = 'polygon'
              this.drawenGeozones[geozone.id] = polygon

              break
            case 'circle':
              const circle = L.circle(geozone.geometry[0], {
                radius: geozone.width,
                color: geozone.color.key,
                weight: 1,
                type: geozone.icon.key,
                renderer: this.myRenderer
              }).bindTooltip(geozone.name, {
                pane: 'geozonenamePane',
                className: this.labelClassNames[geozone.color.value],
                direction: 'bottom'
              })

              // component.addLabel(circle, i)
              circle.options.shape = 'circle'

              this.drawenGeozones[geozone.id] = circle
              // }
              break
            case 'line':
              const line = L.polyline(geozone.geometry, {
                color: geozone.color.key,
                weight: geozone.width || 1,
                type: geozone.icon.key,
                renderer: this.myRenderer
              }).bindTooltip(geozone.name, {
                pane: 'geozonenamePane',
                className: this.labelClassNames[geozone.color.value],
                direction: 'bottom'
              })

              line.options.shape = 'line'

              // component.addLabel(line, i)
              this.drawenGeozones[geozone.id] = line
              break
            case 'point':
              // eslint-disable-next-line no-case-declarations
              const marker = L.marker(geozone.geometry[0], {
                icon: L.icon({
                  iconUrl: `/static/markers/${geozone.icon.key}_${
                    this.markerColors[geozone.color.key]
                  }.svg`,
                  iconSize: [26, 36],

                  iconAnchor: [13, 36]
                }),
                type: geozone.icon.key,
                color: geozone.color.key
              }).bindTooltip(geozone.name, {
                pane: 'geozonenamePane',
                className: this.labelClassNames[geozone.color.value],
                direction: 'bottom'
              })

              marker.options.shape = 'point'
              // component.addLabel(marker, i)
              this.drawenGeozones[geozone.id] = marker
              break
            default:
              break
          }
        }
      })

      this.getGeozones()
    },

    stopsToShow(val) {
      // send the cluster to mapForReports , if the marker exist remove it from cluster , create it on the map , on the next click remove the old marker from the map
      if (val.markers.length) {
        const createtooltipTable = marker => {
          let tooltipTable = ''
          val.columnTable.forEach(column => {
            if (marker[column.key]) {
              tooltipTable += `<tr class="border-b last_border-b-0">
                <td class="px-2 py-1 text-annotationColor font-semibold border-l-0 border-r-0 font-SourceSansPro text-base">${
                  column.name
                }: </td>
                <td class="px-2 py-1 text-darkblue font-bold border-l-0 border-r-0 text-sm">${
                  marker[column.key]
                }</td>
              </tr>
            `
            }
          })
          return tooltipTable
        }

        val.markers.forEach(el => {
          let iconUrl
          if (el.tableType === 'notifications') {
            let stopType
            switch (el.row.notification_type) {
              case 'Контроль скорости':
                stopType = 'velocity_notification'
                iconUrl = `/static/notification_markers_front/${stopType}.svg`
                break
              case 'Потеря связи':
                stopType = 'lostsignal_notification'
                iconUrl = `/static/notification_markers_front/${stopType}.svg`
                break
              case 'Контроль ТО':
                stopType = 'service_notification'
                iconUrl = `/static/notification_markers_front/${stopType}.svg`
                break
              case 'Контроль геозон':
                stopType = 'geozone_notification'
                iconUrl = `/static/notification_markers_front/${stopType}.svg`
                break
              case 'Контроль значения датчика':
                stopType = 'sensor_notification'
                iconUrl = `/static/notification_markers_front/${stopType}.svg`
                break
              case 'Контроль рейсов':
                stopType = 'trips_notification'
                iconUrl = `/static/notification_markers_front/${stopType}.svg`
                break
              case 'Простой ТС':
                stopType = 'idle_notification'
                iconUrl = `/static/notification_markers_front/${stopType}.svg`
                break
              case 'Тревожная кнопка':
                stopType = 'alarm_notification'
                iconUrl = `/static/notification_markers_front/${stopType}.svg`
                break

              default:
                break
            }
          } else if (el.tableType === 'stops') {
            iconUrl = `/static/stops_front/${val.markers[0].tableType}.svg`
          } else if (el.tableType === 'stays') {
            iconUrl = `/static/stops_front/${val.markers[0].tableType}.svg`
          } else if (el.tableType === 'photos') {
            iconUrl = `/static/stops_front/${val.markers[0].tableType}.svg`
          } else if (el.tableType === 'fillings') {
            iconUrl = `/static/stops_front/${val.markers[0].tableType}.svg`
          } else if (el.tableType === 'chronology') {
            iconUrl = `/static/stops_front/${el.row._type_chronology}.svg`
          } else if (el.tableType === 'events') {
            iconUrl = `/static/stops_front/${val.markers[0].tableType}.svg`
          } else if (el.tableType === 'thefts') {
            iconUrl = `/static/stops_front/${val.markers[0].tableType}.svg`
          } else if (el.tableType === 'digital_sensors') {
            iconUrl = `/static/stops_front/${val.markers[0].tableType}.svg`
          } else if (el.tableType === 'driving_style') {
            iconUrl = `/static/events_markers_front/sharp_braking.svg`
          }

          const icon = L.divIcon({
            iconUrl,
            iconAnchor: el.tableType === 'driving_style' ? [13, 26] : '',
            html: `<img class='skif-event-marker__img2' src='${iconUrl}' alt=''>`
          })
          if (el.latlng !== '-') {
            const marker = L.marker(el.latlng, {
              icon
            }).bindPopup(
              `<table style="height:95%" ' overflow:auto' class="table-auto  m-2 border-collapse">
                <tbody>${createtooltipTable(el.row)}<tbody>
              </table>`,
              {
                className: 'stopsPopup',
                offset: L.point(0, -22)
              }
            )
            this.featureGroupStops.addLayer(marker)
            if (el.row.notification_key === 'alarm_notification') {
              this.markerClusterNotifyAnxious.addLayer(marker)
            } else {
              this.markerClusterNotify.addLayer(marker)
            }
          } else {
            console.log('found x2')
          }
        })
        // this.featureGroupStops.addTo(this.map)
      } else {
        // this.map.removeLayer(this.featureGroupStops)
        this.featureGroupStops.eachLayer(marker => {
          this.markerClusterNotify.removeLayer(marker)
        })
        this.featureGroupStops.clearLayers()
      }

      this.$store.commit('map/REPORTS_MARKERS', this.markerClusterNotify)
    },
    currentZoom(newVal, oldVal) {
      // console.log(newVal, oldVal, 'Zoom Updated');
    }
  },

  methods: {
    ...mapMutations({
      clearVuexMarkersOnMap: 'monitoringNew/clearVuexMarkersOnMap',
      clearVuexActiveGroupArrow: 'monitoringNew/clearVuexActiveGroupArrow',
      clearVuexActiveArrow: 'monitoringNew/clearVuexActiveArrow',
      setVuexIsActiveAllArrow: 'monitoringNew/setVuexIsActiveAllArrow',
      addVuexMarkersOnMap: 'monitoringNew/addVuexMarkersOnMap',
      addVuexMarkersUnitOnMap: 'monitoringNew/addVuexMarkersUnitOnMap',
      setVuexAnnotationUnit: 'monitoringNew/setVuexAnnotationUnit',
      setVuexIsAnnotation: 'monitoringNew/setVuexIsAnnotation',
      deleteVuexMarkersOnMap: 'monitoringNew/deleteVuexMarkersOnMap',
      deleteVuexMarkerUnitOnMap: 'monitoringNew/deleteVuexMarkerUnitOnMap'
    }),
    checkStartWs() {
      if(!this.wsStarted) {
        this.$emit('set-websocket');
        this.wsStarted = true;
      }
    },
    initMap() {
      const map = L.map(this.$refs.map, {
        attributionControl: false,
        drawControl: true,
        zoomControl: false,
        editable: true,
        doubleClickZoom: false,
        maxZoom: 20,
        minZoom: 0,
        crs: L.CRS.EPSG3395,
        preferCanvas: true
      })
      // this.myRenderer = L.canvas()
      this.tileLayer = L.tileLayer(this.selectedLayerName)

      this.map = map
      // прокидываем карты в геозоны
      this.$emit('map-for-geozones', this.map)
      this.markerClusterNotify = L.markerClusterGroup({
        iconCreateFunction(cluster) {
          return L.divIcon({
            className: 'marker-cluster-group__notify',
            html: `<div class="marker-group marker-group__notify"><span>${cluster.getChildCount()}</span></div>`
          })
        }
      })

      // Кластеры уведомлений
      this.markerClusterNotify.on('clusterclick', function () {
        const canv = document.getElementsByTagName('canvas')

        if (canv.length > 1) {
          if (navigator.userAgent.indexOf('Chrome') !== -1) {
            canv[0].style.zIndex = '101'
            canv[1].style.zIndex = '100'
          }
          if (navigator.userAgent.indexOf('Firefox') !== -1) {
            canv[0].style.zIndex = '101'
            canv[1].style.zIndex = '100'
          }
        }
      })
      let clusterRadiusCount = 100
      if (!this.isHidingNotification) {
        this.map.addLayer(this.markerClusterNotify)
      }
      this.markerClusterNotifyAnxious = L.markerClusterGroup({
        chunkedLoading: true,
        maxClusterRadius: () => {
          if (localStorage.getItem('clusterRadiusCount')) {
            clusterRadiusCount = localStorage.getItem('clusterRadiusCount')
          }
          return clusterRadiusCount
        },
        iconCreateFunction(cluster) {
          return L.divIcon({
            className: 'marker-cluster-group__notify-anxious',
            html: `<div class="marker-group marker-group__notify-anxious"><span>${cluster.getChildCount()}</span></div>`
          })
        }
      })
      this.markerClusterNotifyAnxious.on('clusterclick', function () {
        const canv = document.getElementsByTagName('canvas')

        if (canv.length > 1) {
          if (navigator.userAgent.indexOf('Chrome') !== -1) {
            canv[0].style.zIndex = '101'
            canv[1].style.zIndex = '100'
          }
          if (navigator.userAgent.indexOf('Firefox') !== -1) {
            canv[0].style.zIndex = '101'
            canv[1].style.zIndex = '100'
          }
        }
      })
      this.map.addLayer(this.markerClusterNotifyAnxious)

      this.markersObjectGroup = L.markerClusterGroup({
        chunkedLoading: true,
        maxClusterRadius: () => {
          if (localStorage.getItem('clusterRadiusCount')) {
            clusterRadiusCount = localStorage.getItem('clusterRadiusCount')
            return clusterRadiusCount
          }
        },
        iconCreateFunction(cluster) {
          return L.divIcon({
            className: 'marker-cluster-group__object',
            html: `<div class="marker-group marker-group__object"><span>${cluster.getChildCount()}</span></div>`
          })
        }
      })

      this.markersObjectGroup.on('clusterclick', () => {
        const canv = document.getElementsByTagName('canvas')

        if (canv.length > 1) {
          if (navigator.userAgent.indexOf('Chrome') !== -1) {
            canv[0].style.zIndex = '101'
            canv[1].style.zIndex = '100'
          }
          if (navigator.userAgent.indexOf('Firefox') !== -1) {
            canv[0].style.zIndex = '101'
            canv[1].style.zIndex = '100'
          }
        }
      })
      this.map.addLayer(this.markersObjectGroup)

      this.markerClusterEvents = L.markerClusterGroup({
        chunkedLoading: true,
        maxClusterRadius: () => {
          if (localStorage.getItem('clusterRadiusCount')) {
            clusterRadiusCount = localStorage.getItem('clusterRadiusCount')
          }
          return clusterRadiusCount
        },
        iconCreateFunction(cluster) {
          return L.divIcon({
            className: 'skif-event-claster__wrapper',
            html: `<div class="skif-event-claster"><span>${cluster.getChildCount()}</span></div>`
          })
        }
      })
      this.map.addLayer(this.markerClusterEvents)
      this.map.createPane('geozonenamePane')

      this.map.setView(this.center, this.zoom)
      setTimeout(() => (this.automaticSetView = true), 100)
      L.control
        .zoom({
          position: 'bottomright'
        })
        .addTo(this.map)
      // слушатели событий
      this.map
        .on('baselayerchange', e => {
          this.selectedLayerName = e.name
          if (e.name === 'Yandex Map') {
            this.map.options.crs = L.CRS.EPSG3395
          } else {
            this.map.options.crs = L.CRS.EPSG3857
          }
          this.setView()
        })
        .on('mouseup', () => {
          if (!this.programmaticMapManipulation) {
            this.$emit('setMonitoringButton', false)
          }
          if (this.isActiveMonitoringButton && !this.isMonitoringManipulation) {
            this.$emit('is-active-monitoring-button', false)
          }
        })
        // при перетаскивании карты
        .on('moveend', () => {
            const BOX_TRACK_OPENED = this.$store.getters['monitoringNew/boxTrackIsOpened']
            if (BOX_TRACK_OPENED) {  // Открыт box-track
              moveEndMapForBoxTrack(this)  // Инициализируем box-track
            }
            this.zoom = this.map.getZoom()
            this.center = this.map.getCenter()
          },

          this
        )
        .on('mouseout', () => {
          if (this.measureDrag) {
            this.map.removeLayer(this.measureDrag)
            this.measureDrag = null
          }
        })
        .on('click', (event) => {
          this.coordinateForTrack = event.latlng
        })
        .on('zoomend', async () => {
          // console.log('zoom');
        })
      this.changeBaseLayer()
      this.mlayer = L.layerGroup().addTo(this.map)
      this.monitoringFeatureGroup = L.featureGroup().addTo(this.map)
      // слой для аннотаций
      this.annotationLayer = L.layerGroup().addTo(this.map);
      this.changeBaseLayer()
    },

    collectMoveEnd() {
      this.zoom = this.map.getZoom()
      this.center = this.map.getCenter()
    },

    setView(center, zoom = 17) {
      if (this.searchAddressMarker) {
        this.map.removeLayer(this.searchAddressMarker)
      }
      if (center) {
        this.map.setView(center, zoom)
        this.searchAddressMarker = L.marker(center, {
          icon: L.icon({
            iconUrl: `/static/markers/territory_blue.svg`,
            iconSize: [26, 36],
            iconAnchor: [13, 36]
          })
        })
        this.searchAddressMarker.addTo(this.map)
      } else {
        this.map.setView(this.center, zoom)
        this.searchAddressMarker = L.marker(center, {
          icon: L.icon({
            iconUrl: `/static/markers/territory_blue.svg`,
            iconSize: [26, 36],
            iconAnchor: [13, 36]
          })
        })
        this.searchAddressMarker.addTo(this.map)
      }
    },

    selectLayer(layer) {
      this.selectedLayerName = layer
      this.changeBaseLayer()
    },

    changeBaseLayer() {
      // очищаем кластеры чтобы не было ошибки
      this.markersCluster.clearLayers()
      this.markerGroupUnits.clearLayers()

      const center = this.map.getCenter()
      if (this.baseLayer.removeFrom) {
        this.baseLayer.removeFrom(this.map)
      }
      if (this.selectedLayerName === 'Yandex Map') {
        this.map.options.crs = L.CRS.EPSG3395
      } else {
        this.map.options.crs = L.CRS.EPSG3857
      }
      this.baseLayer = this.baseLayers[this.selectedLayerName]
      this.baseLayer.addTo(this.map)
      this.map.panTo(center)
    },

    updateGeometry(geometry, radius) {
      this.geometry = geometry
      this.radius = radius
    },
    addOverlay(overlay) {
      switch (overlay) {
        case 'Traffic Jam':
          console.log('traffic data')

          break
        case 'Temperature':
          this.tempOverlay.addTo(this.map)

          break
        case 'Clouds':
          this.cloudsOverlay.addTo(this.map)
          break
        case 'Wind':
          this.windOverlay.addTo(this.map)
          break
        default:
          break
      }
    },
    removeOverlay(overlay) {
      switch (overlay) {
        case 'Temperature':
          this.tempOverlay.removeFrom(this.map)
          break
        case 'Clouds':
          this.cloudsOverlay.removeFrom(this.map)
          break
        case 'Wind':
          this.windOverlay.removeFrom(this.map)
          break
        default:
          break
      }
    },
    resetLabels(layers) {
      let i = 0
      const component = this
      layers.forEach(label => {
        component.addLabel(label, ++i)
      })
      this.labelEngine.update()
    },
    addLabel(layer, id) {
      const label = layer.getTooltip()
        ? layer.getTooltip()._source._tooltip._container
        : null
      if (label) {
        const rect = label.getBoundingClientRect()
        const bottomLeft = this.map.containerPointToLatLng([rect.left, rect.bottom])
        const topRight = this.map.containerPointToLatLng([rect.right, rect.top])
        const boundingBox = {
          bottomLeft: [bottomLeft.lng, bottomLeft.lat],
          topRight: [topRight.lng, topRight.lat]
        }
        this.labelEngine.ingestLabel(
          boundingBox,
          id,
          parseInt(Math.random() * (5 - 1) + 1, 10),
          label,
          `Test ${id}`,
          false
        )
        if (!layer.added) {
          layer.addTo(this.map)
          layer.added = true
        }
      }
    }
  },

  mounted() {
    const clusterRadiusCount = JSON.parse(localStorage.getItem('clusterRadiusCount'))

    this.initMap()

    this.map.addLayer(this.markerGroup)
    this.map.addLayer(this.markerGroupUnits)
    this.map.addLayer(this.markersCluster)
    this.$emit('marker-cluster', this.markersCluster)
    this.$emit('marker-group', this.markerGroup)
    this.$emit('marker-group-units', this.markerGroupUnits)

    if (clusterRadiusCount) {
      this.markersCluster.options.maxClusterRadius = Number(clusterRadiusCount)
    }

    this.toggleNotification = JSON.parse(localStorage.getItem('hideNotification'))
    this.$store.commit('map/TOGGLE_NOTIFICATION', this.toggleNotification)
    const trackWeight = localStorage.getItem('trackWeight')
    if (trackWeight) {
      this.$store.commit('map/SETTRACK_WEIGHT', Number(trackWeight))
    }
    // Настройка паддингов для карты (задача box-track)
    this.map.getRenderer(this.map).options.padding = 1

    setTimeout(() => {
      this.checkStartWs();
    }, 300)
  },

  beforeDestroy() {
    this.map.removeLayer(this.baseLayers[this.selectedLayerName])
  }
}
</script>
<style>
.photosPopup {
  width: 350px;
}
.stopsPopup .leaflet-popup-content {
  /* height: 400px; */
  overflow-y: auto;
  scrollbar-color: rgba(51, 51, 51, 0.3) #ffffff;
  scrollbar-width: thin;
}
.stopsPopup .leaflet-popup-content::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.stopsPopup .leaflet-popup-content::-webkit-scrollbar-track {
  background-color: #ffffff;
}
.stopsPopup .leaflet-popup-content::-webkit-scrollbar-thumb {
  background-color: rgba(51, 51, 51, 0.3);
  border-radius: 4px;
}
</style>

<style lang="stylus">
.hidden-square
  width 0 !important
  height 0 !important
  margin 0 !important

.icon-event
  background-color white
  border-radius 50%
  border 1px solid #EFF1F4
.skif-event-claster, .skif-event-marker {
  display: flex;
  width: 35px;
  height: 36px;
  color: #5477A9;
}

.skif-event-claster {
  width: 35px;
  height: 27px;
  background: #FFFFFF;
  box-shadow: 1px 3px 7px rgba(37, 37, 37, 0.45);
  border-radius: 5px;

  span {
    display: inline-block;
    margin: auto;
    font-size: 17px;
    font-weight: bold;
  }

  &__wrapper {
    background-color: tranparent;
    border: none;
  }
}

.skif-event-marker {
  background: url('/static/events_markers_front/bg.svg') no-repeat transparent;
  background-size: cover;

  &__img {
    width: 28px;
    height: 31px;
    display: block;
    margin: 6px auto auto 13px;
  }

  .trackIcon {
    background-color: none !important;
  }
}

.skif-event-marker-withoutbg {
  background: none !important;
  width 0 !important;
  height 0 !important;
}

.marker-group {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }

  &__notify {
    background: rgba(230, 138, 47, 0.3);

    span {
      background: #E68A2F;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  &__object {
    background: rgba(47, 128, 237, 0.3);

    span {
      background: #2F80ED;
      font-size: 12px;
      color: white;
    }
  }

  &__notify-anxious {
    background: rgba(245, 108, 108, 0.3);

    span {
      background: #F56C6C;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.7);
    }
  }
}
</style>
